import React, { useEffect, useState } from "react";
import { CustomPostAPI } from "../Utils/APIConnector";
import {
  convertTo12HourFormat,
  formatDate,
  VenueDate,
  venueSportsData,
} from "../Utils/common";
import { Card } from "react-bootstrap";
import Loader from "../Utils/Loader";
import Swal from "sweetalert2";

export default function VenueOwnerDashbooardBooking({
  selectedVenue,
  activeCourtID,
  showoffline,
}) {
  const [showBookingDetails, setShowBookingDetails] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const UserType = localStorage.getItem("UserType");
  const cancel = (id, Isrecurring) => {
    if (Isrecurring) {
      Swal.fire({
        title: "Cancel Future Event also",
        text: "This is a recurring booking. Do you want to cancel all future events?",
        showConfirmButton: true,
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          CustomPostAPI(`/payment/OfflineCancelbooking/${id}/true`).then(
            (result) => {
              if (result) {
                setIsLoading(false);
                Swal.fire({
                  title: "Success",
                  text: "Booking cancelled successfully",
                  showConfirmButton: true,
                  confirmButtonText: "Ok",
                }).then((result) => {
                  // window.location.reload();
                  getdata();
                });
              } else {
                setIsLoading(false);
                alert("Something went worng");
              }
            }
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          setIsLoading(true);
          CustomPostAPI(`/payment/OfflineCancelbooking/${id}/false`).then(
            (result) => {
              if (result) {
                setIsLoading(false);
                Swal.fire({
                  title: "Success",
                  text: "Booking cancelled successfully",
                  showConfirmButton: true,
                  confirmButtonText: "Ok",
                }).then((result) => {
                  // window.location.reload();
                  getdata();
                });
              } else {
                setIsLoading(false);
                alert("Something went worng");
              }
            }
          );
        }
      });
    } else {
      setIsLoading(true);
      CustomPostAPI(`/payment/OfflineCancelbooking/${id}/false`).then(
        (result) => {
          if (result) {
            setIsLoading(false);
            Swal.fire({
              title: "Success",
              text: "Booking cancelled successfully",
              showConfirmButton: true,
              confirmButtonText: "Ok",
            }).then((result) => {
              // window.location.reload();
              getdata();
            });
          } else {
            setIsLoading(false);
            alert("Something went worng");
          }
        }
      );
    }
    // setIsLoading(true);
  };

  useEffect(() => {
    getdata();

    // setIsLoading(true);
  }, [selectedVenue, selectedDate, activeCourtID, showoffline?.popup]);

  function getdata() {
    const raw = JSON.stringify({
      VenueId: selectedVenue,
      Date: selectedDate,
      venuecourtid: activeCourtID,
    });
    CustomPostAPI("/Venue/GetBookedvenueOwnerside", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        setShowBookingDetails({
          bookingDetails: result.bookingDetails,
          venueDetails: result.venueDetails,
        });
        setIsLoading(false);
      }
    });
  }
  return (
    <React.Fragment>
      <div className="Montserrat fs-14 fw-semibold justify-content-between d-flex align-items-center mt-3">
        <div>
          <span className="cl-A51E22">
            {showBookingDetails?.bookingDetails?.length} bookings
          </span>{" "}
          <span>on {VenueDate(selectedDate).formattedDate}</span>
        </div>
        <button
          id="DatePicker"
          className="datepicker-native"
          style={{ background: "transparent", border: "none" }}
        >
          <img src="/images/LatestCalenderBooking.svg" alt="" />
          <input
            type="date"
            className="datepicker-native-input"
            value={selectedDate}
            onChange={(e) => {
              setSelectedDate(e.target.value);
              setIsLoading(true);
            }}
          />
        </button>
      </div>
      {isLoading ? (
        <Loader mt="20%" />
      ) : (
        <>
          {showBookingDetails?.bookingDetails?.length > 0 ? (
            <>
              {showBookingDetails?.bookingDetails?.map((booking) => (
                <>
                  <Card className="mt-3 border-0 ">
                    <Card.Body className="bg-FAF4F4 borderRadius-6">
                      <div className="d-flex align-items-center Montserrat fw-500 cl-A51E22 fs-12 justify-content-between">
                        <div>
                          {formatDate(booking?.date?.split("T")[0])
                            .split(" ")
                            .slice(0, 2)
                            .join(" ")}{" "}
                          <img
                            className="mx-1"
                            src="/images/centeredDot.svg"
                            alt=""
                          />
                          {convertTo12HourFormat(booking?.startTimelist)}(
                          {booking?.slotDuration}
                          {booking?.slotDuration > 1 ? "hrs" : "hr"})
                        </div>{" "}
                        {booking?.isOfflineBooking && UserType != "Staff" && (
                          <div>
                            <img
                              src="/images/CancelBookingIcon.svg"
                              alt=""
                              onClick={(e) =>
                                Swal.fire({
                                  title: "Cancel Booking",
                                  text: "are you sure you want to cancel?",
                                  showConfirmButton: true,
                                  confirmButtonText: "Yes",
                                  showCancelButton: true,
                                  cancelButtonText: "No",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    cancel(booking?.id, booking?.isrecurring);
                                  }
                                })
                              }
                            />
                          </div>
                        )}
                      </div>
                      <div className="d-flex">
                        <div className="w-50">
                          <div className="Montserrat fw-600">
                            <span className="m-0">{booking?.username}</span>
                            <div style={{ marginTop: "-8px" }}>
                              <span
                                className="fw-500 fs-12"
                                style={{ color: "#000000B2" }}
                              >
                                {booking?.userPhone}
                              </span>
                            </div>
                          </div>
                          <div
                            className="Montserrat fw-500 fs-12 pt-1"
                            style={{ color: "#000000B2" }}
                          >
                            <img
                              src={`/images/${venueSportsData(booking?.sport)}`}
                              alt=""
                              className="me-1"
                              style={{ opacity: "0.45" }}
                            />
                            {booking?.sport}
                          </div>
                          <div
                            className="Montserrat fw-500 fs-12"
                            style={{ color: "#000000B2" }}
                          >
                            ({booking?.surface})
                          </div>
                        </div>
                        <div className="w-50 text-end Montserrat align-items-end justify-content-end d-flex flex-column">
                          {/* {booking?.isOfflineBooking ? (
                            <div className="fw-semibold">
                              <label>Total</label> -{" "}
                              <span>₹{booking?.toPayAmount}</span>
                            </div>
                          ) : ( */}
                          <>
                            <div className="fs-14 cl-000000B2">
                              <label>Remaining</label> -{" "}
                              <span>₹{booking?.toPayAmount}</span>
                            </div>
                            {booking?.offlineDiscountAmount > 0 && (
                              <div className="fs-14">
                                <label>Discount</label> -{" "}
                                <span>₹{booking?.offlineDiscountAmount}</span>
                              </div>
                            )}
                            <div className="fs-14 cl-008A45">
                              <label>Paid</label> -{" "}
                              <span>₹{booking?.amount}</span>
                            </div>
                            <div className="fw-semibold">
                              <label>Total</label> -{" "}
                              <span>
                                ₹
                                {booking?.amount +
                                  booking?.toPayAmount+ booking?.offlineDiscountAmount}
                              </span>
                            </div>
                          </>
                          {/* )} */}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </>
              ))}
            </>
          ) : (
            <div className="Montserrat fs-14 fw-semibold justify-content-between d-flex align-items-center">
              No Booking Found
            </div>
          )}
        </>
      )}
    </React.Fragment>
  );
}
