import React from "react";

export default function AppLink() {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 text-center">
          <b>
            Find & book perfect sports venues with <br />{" "}
            <label style={{ color: "#a51e22" }}>Cricbuddy</label> app.
          </b>
        </div>
        <div className="col-12 text-center">
          <img src="/images/CBQR.svg" alt="" />
        </div>
      </div>
      <div className="row ">
        <div className="col-12 text-center ">
          <a
            target="_"
            href="https://play.google.com/store/apps/details?id=com.sprigstack.cric_buddy"
            rel="nofollow"
          >
            <img className="img-fluid" src="/images/playstore.png" alt="" />
          </a>
          <a
            href="https://apps.apple.com/in/app/cricbuddy/id6480105922"
            target="_"
            rel="nofollow"
          >
            <img className="img-fluid" src="/images/appstore.png" alt="" />
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}
