import React, { useEffect, useState } from "react";
import $ from "jquery";
import { CustomPostAPI } from "./Utils/APIConnector";
import { useCookies } from "react-cookie";
import { isValid } from "./constants";
import Cookies from "universal-cookie";
import { UserContext } from "../App";
import { useContext } from "react";

export default function VerifyPhoneNumber(props) {
  const { setAuth } = useContext(UserContext);
  const [showLoader, SetShowLoader] = useState(false);
  const [phonenumber, Setphonenumber] = useState();
  const [cookies, setCookie] = useCookies(["User"]);
  const redirectURL = window.localStorage.getItem("RedirectURL");
  useEffect(() => {
    props.phonenumber && timerOTP();
    props.phonenumber && SendOTP();
  }, [props.phonenumber]);

  const SendOTP = async () => {
    SetShowLoader(true);
    var mobileNo = $("#UserMobileNo").val();
    var pattern = new RegExp(/^[0-9\b]+$/);
    $("#UserMobileNo").parent().find("span").text("");
    $("#UserMobileNo").val(mobileNo);
    if (!pattern.test(mobileNo)) {
      $("#UserMobileNo")
        .parent()
        .find("span")
        .text("Please enter valid number");
      SetShowLoader(false);
      return;
    } else if (mobileNo.length < 10) {
      $("#UserMobileNo")
        .parent()
        .find("span")
        .text("Please enter 10 digit mobile number");
      SetShowLoader(false);
      return;
    }
    var raw = JSON.stringify({
      PhoneNumber: mobileNo,
      isfromvenueowner: props.isfromvenueowner,
    });
    $("#OTPInputDiv").removeClass("d-none");
    $("#sendOTPBtn").addClass("d-none");
    $("#OTPmsgdiv").removeClass("d-none");
    $("#verifyOTPBtn").removeClass("d-none");
    $("#ResendOTP").removeClass("d-none");
    // timerOTP();
    SetShowLoader(false);
    await CustomPostAPI("/Auth/AddorVerifyUser", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
        SetShowLoader(false);
      } else {
        result = JSON.parse(result);
        // console.log(result)
        $("#OTPInputDiv").removeClass("d-none");
        $("#sendOTPBtn").addClass("d-none");
        $("#verifyOTPBtn").removeClass("d-none");
        $("#ResendOTP").removeClass("d-none");
        timerOTP();
        SetShowLoader(false);
      }
    });
  };

  const validateMobileNo = (e) => {
    if (e.target.value.length > 10) {
      return;
    }
    Setphonenumber(e.target.value);
  };

  const resendOTP = () => {
    var resendLink = $("#ResendOTP").find("a").addClass("d-none");
    SetShowLoader(true);
    var mobileNo = $("#UserMobileNo").val();
    var pattern = new RegExp(/^[0-9\b]+$/);
    $("#UserMobileNo").parent().find("span").text("");
    $("#UserMobileNo").val(mobileNo);
    if (!pattern.test(mobileNo)) {
      $("#UserMobileNo")
        .parent()
        .find("span")
        .text("Please enter valid number");
      SetShowLoader(false);
      return;
    } else if (mobileNo.length < 10) {
      $("#UserMobileNo")
        .parent()
        .find("span")
        .text("Please enter 10 digit mobile number");
      SetShowLoader(false);
      return;
    }
    var raw = JSON.stringify({
      PhoneNumber: mobileNo,
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      process.env.REACT_APP_API_BASE_URL + "/Auth/AddorVerifyUser",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.status == "Success") {
          $("#ResendOTP").removeClass("d-none");
          timerOTP();
          SetShowLoader(false);
        } else {
          alert(result.message);
          SetShowLoader(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const timerOTP = () => {
    var timerDiv = $("#countdowntimer");
    var resendLink = $("#ResendOTP").find("a");
    var timeleft = 59;
    var resendtimer = $("#resendtimer").removeClass("d-none");
    var downloadTimer = setInterval(function () {
      timeleft--;
      timeleft = (timeleft < 10 ? "0" : "") + timeleft.toString();
      timerDiv.html(timeleft);
      if (timeleft <= 0) {
        $(resendLink).removeClass("d-none");
        var resendtimer = $("#resendtimer").addClass("d-none");
        clearInterval(downloadTimer);
      }
    }, 1000);
  };

  const verifyOTP = async () => {
    SetShowLoader(true);
    var OTP = $("#NewUserOTP").val();
    if (OTP.length == 0) {
      $("#NewUserOTP").parent().find("span").text("Please enter valid OTP");
      SetShowLoader(false);
      return;
    } else if (OTP.length < 6) {
      $("#NewUserOTP").parent().find("span").text("Please enter 6 digit OTP");
      SetShowLoader(false);
      return;
    } else if (OTP.length > 6) {
      $("#NewUserOTP").parent().find("span").text("Please enter 6 digit OTP");
      SetShowLoader(false);
      return;
    } else if (OTP.length > 6) {
      $("#UserOTP").parent().find("span").text("Please enter 6 digit OTP");
      SetShowLoader(false);
      return;
    }
    var mobileNo = $("#UserMobileNo").val();
    var raw = JSON.stringify({
      PhoneNumber: mobileNo,
      VerifyCode: OTP,
    });
    await CustomPostAPI("/Auth/VerifyWhatsappCode", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
        SetShowLoader(false);
      } else {
        SetShowLoader(false);

        result = JSON.parse(result);
        if (result.status == "Success") {
          props.setIsFirstTime && props.setIsFirstTime("1");
          props.setcurrentStep && props.setcurrentStep(2);
          props.setshowLogin && props.setshowLogin(false);
          const cookies = new Cookies();
          // cookies.set("IsPersist", "yes", {
          //   maxAge: 1440000,
          // });
          cookies.set("userid", result.userDetails.id, {
            maxAge: 1440000,
          });
          window.localStorage.setItem(
            "phoneno",
            result.userDetails.phoneNumber
          );

          setCookie("AuthToken", result.token, { path: "/" });

          window.localStorage.setItem("userid", result.userDetails.id);
          var userdetailsdata = result.userDetails;

          if (userdetailsdata.statusId == 2) {
            SetShowLoader(false);

            if (userdetailsdata.roleType === 1) {
              window.localStorage.setItem("UserType", "User");
            } else if (userdetailsdata.roleType === 2) {
              window.localStorage.setItem("UserType", "Admin");
            } else if (userdetailsdata.roleType === 3) {
              window.localStorage.setItem("UserType", "Venueowner");
            } else if (userdetailsdata.roleType === 4) {
              window.localStorage.setItem("UserType", "Hybrid");
            } else if (userdetailsdata.roleType === 5) {
              window.localStorage.setItem("UserType", "Staff");
            }
            window.localStorage.setItem(
              "UserName",
              result.userDetails.firstName
                ? result.userDetails.firstName
                : "" + " " + result.userDetails.lastName
                ? result.userDetails.lastName
                : ""
            );
            window.localStorage.setItem("UserEmail", result.userDetails.email);
            if (isValid(result.userDetails.profileImage)) {
              window.localStorage.setItem(
                "UserProfileImage",
                result.userDetails.profileImage
              );
            }
            window.localStorage.setItem(
              "UserMobileNo",
              result.userDetails.phoneNumber
            );
            window.localStorage.setItem(
              "UserProfileStatus",
              result.progressbarValue
            );
            localStorage.setItem("loggedin", "true");
            {
              if (props?.isActive != 0) {
                props?.isActive === 4
                  ? props.setshowpayment && props.setshowpayment(true)
                  : props.setshowpayment && props.setshowpayment(true);
              }
            }
          }
          setAuth(true);
        } else if (result.status == "Invalid") {
          $("#NewUserOTP").parent().find("span").text("Please enter valid OTP");
        }
      }
    });
  };
  return (
    <React.Fragment>
      {props.phonenumber ? (
        <div className="">
          <div style={{ marginTop: "7%" }}>
            <div className="col-12 ">
              <img src="/images/avtar.svg" className="avtarImg" />
            </div>
            <div className="col-12 text-center mt-2">
              <span className="formHeading">Verify Your Number</span>
            </div>
            <div id="OTPmsgdiv" className="d-none text-center">
              <span>Please Provide Whatsapp Number</span>
            </div>
            <div id="OTPmsgdiv" className="d-none">
              <span>You will receive the OTP on your WhatsApp number.</span>
            </div>
          </div>
          <div className="row p-2">
            <div className="col-12 text-center my-2 resendOTPClass d-none">
              <span
                className="errorTxt"
                id="errorTxt"
                style={{ color: "#A51E22", fontSize: "14px" }}
              ></span>
            </div>
            <div className="col-12  pt-3">
              <div className="posRel">
                <img src="/images/contact.svg" className="inputIcons1" />
                {props.phonenumber ? (
                  <input
                    id="UserMobileNo"
                    type="number"
                    inputMode="numeric"
                    className="form-control disableClass formControl"
                    placeholder="Mobile Number"
                    autoComplete="new-password"
                    value={props.phonenumber}
                    disabled
                    // onChange={validateMobileNo}
                    maxLength={10}
                  />
                ) : (
                  <input
                    id="UserMobileNo"
                    type="number"
                    inputMode="numeric"
                    className="form-control disableClass formControl"
                    placeholder="Mobile Number"
                    autoComplete="new-password"
                    maxLength={10}
                  />
                )}

                <span className="ErrorText" style={{ color: "#A51E22" }}></span>
              </div>
            </div>
            <div className="col-12  pt-2">
              <div className="posRel" id="OTPInputDiv">
                <img src="/images/OTP1.svg" className="inputIcons1 topotp" />
                <input
                  id="NewUserOTP"
                  type="number"
                  inputMode="numeric"
                  className="form-control formControl"
                  placeholder="OTP"
                  // onChange={validateOTP}
                />
                <span className="ErrorText" style={{ color: "#A51E22" }}></span>
              </div>
            </div>

            <div className="col-12 text-center my-2">
              <div className="justify-content-center">
                <button
                  type="submit"
                  id="verifyOTPBtn"
                  className="mt-2 col-6 loginbtn "
                  onClick={verifyOTP}
                >
                  Verify OTP
                </button>
                {showLoader ? (
                  <img
                    id="loaderImg"
                    className="col-1 hidden"
                    height="30px"
                    width="30px"
                    src="/images/loder.svg"
                  />
                ) : null}
              </div>

              <div
                id="ResendOTP"
                className={`${props.phonenumber ? "" : "d-none"} px-2`}
              >
                <div className="pt-2">
                  <h6 id="resendtimer">
                    Resend OTP in 00:<span id="countdowntimer">59</span>
                  </h6>
                </div>
                <a
                  style={{ color: "#0d6efd", textDecoration: "underline" }}
                  className="d-none"
                  onClick={resendOTP}
                >
                  Resend OTP
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <div style={{ marginTop: "10%" }}>
            <div className="col-12 ">
              <img src="/images/avtar.svg" className="avtarImg" />
            </div>

            <div className="col-12 text-center mt-2">
              <span className="formHeading">Verify Your Number</span>
            </div>
            <div id="OTPmsgdiv" className=" text-center">
              <small className="text-center">
                You will receive the OTP on your WhatsApp number
              </small>
            </div>
          </div>
          <div className="row p-2">
            <div className="col-12 text-center my-2 resendOTPClass d-none">
              <span
                className="errorTxt"
                id="errorTxt"
                style={{ color: "#A51E22", fontSize: "14px" }}
              ></span>
            </div>
            <div className="col-12  pt-3">
              <div className="posRel">
                <img src="/images/contact.svg" className="inputIcons1" />
                {props.phonenumber ? (
                  <input
                    id="UserMobileNo"
                    type="number"
                    inputMode="numeric"
                    className="form-control disableClass formControl"
                    placeholder="Mobile Number"
                    autoComplete="new-password"
                    value={props.phonenumber}
                    disabled
                    // onChange={validateMobileNo}
                    maxLength={10}
                  />
                ) : (
                  <input
                    id="UserMobileNo"
                    type="number"
                    inputMode="numeric"
                    className="form-control disableClass formControl"
                    placeholder="Mobile Number"
                    autoComplete="new-password"
                    value={phonenumber}
                    onChange={validateMobileNo}
                    maxLength={10}
                  />
                )}

                <span className="ErrorText" style={{ color: "#A51E22" }}></span>
              </div>
            </div>
            <div className="col-12  pt-2">
              <div className="posRel d-none" id="OTPInputDiv">
                <img src="/images/OTP1.svg" className="inputIcons1 topotp" />
                <input
                  id="NewUserOTP"
                  type="number"
                  inputMode="numeric"
                  className="form-control formControl"
                  placeholder="OTP"
                  // onChange={validateOTP}
                />
                <span className="ErrorText" style={{ color: "#A51E22" }}></span>
              </div>
            </div>

            <div className="col-12 text-center my-2">
              <div className="justify-content-center">
                <button
                  type="submit"
                  id="sendOTPBtn"
                  className="mt-2 col-6 w-auto loginbtn"
                  onClick={SendOTP}
                >
                  Send Verification Code
                </button>
                <button
                  type="submit"
                  id="verifyOTPBtn"
                  className="mt-2 col-6 loginbtn d-none"
                  onClick={verifyOTP}
                >
                  Verify OTP
                </button>
                {showLoader ? (
                  <img
                    id="loaderImg"
                    className="col-1 hidden"
                    height="30px"
                    width="30px"
                    src="/images/loder.svg"
                  />
                ) : null}
              </div>

              <div id="ResendOTP" className="d-none px-2">
                <div className="pt-2">
                  <h6>
                    Resend OTP in 00:<span id="countdowntimer">59</span>
                  </h6>
                </div>
                <a
                  style={{ color: "#0d6efd", textDecoration: "underline" }}
                  className="d-none"
                  onClick={resendOTP}
                >
                  Resend OTP
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
