import { faWindows } from "@fortawesome/free-brands-svg-icons";
import React, { Fragment, useEffect, useState } from "react";
import { CustomGetAPI } from "../Utils/APIConnector";
import VideoLooper from "react-video-looper";
import Popup from "../LiveScoreCardpopups/Popup";
import VenueSearch from "../VenueListing/venueSearch";

export default function Booking({ data }) {
  const userLocation = window.localStorage.getItem("userLocation");
  const initialHeight = window.innerWidth >= 767 ? "50vh" : "20vh";
  const [height, setHeight] = useState(initialHeight);
  const [selectedCity, setSelectedCity] = useState("");
  const [cityData, setCityData] = useState();
  const [show, setShow] = useState(false);
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setwindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });
  useEffect(() => {
    CustomGetAPI("/venue/GetAllCity").then((result) => {
      result = JSON.parse(result);
      if (result?.status === "success") {
        setCityData(result?.cities);
      }
    });

    const handleResize = () => {
      const newHeight = window.innerWidth >= 767 ? "50vh" : "20vh";
      setHeight(newHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="row latestlandingpage-booking-bg align-items-center position-relative px-md-5 px-2 flex-column-reverse flex-md-row">
        <div className="col-md-5 py-md-5 px-md-4 mb-4 px-4 mt-0">
          <h1 className="latestlandingpage-booking-firsttext">
            Online sports venue
            <br />
            booking made easy!
          </h1>
          <div className="mt-md-4 mt-0 g-3 d-flex justify-content-center justify-content-md-start">
            <a
              target="_"
              href="https://play.google.com/store/apps/details?id=com.sprigstack.cric_buddy"
              rel="nofollow"
            >
              <img
                src="/images/Playstore.svg"
                alt="Playstore"
                className="latestlandingpage-booking-img mt-2"
                style={{
                  height: "7vh",
                  boxShadow: "0px 1.14px 4.57px 0px #00000024",
                  borderRadius: "10px",
                }}
              />
            </a>
            <a
              className="ms-4"
              href="https://apps.apple.com/in/app/cricbuddy/id6480105922"
              target="_"
              rel="nofollow"
            >
              <img
                src="/images/Appstore.svg"
                alt="Appstore"
                className="latestlandingpage-booking-img mt-2"
                style={{
                  height: "7vh",
                  boxShadow: "0px 1.14px 4.57px 0px #00000024",
                  borderRadius: "10px",
                }}
              />
            </a>
          </div>

          <div className="row align-items-center latestlandingpage-booking-book p-4 mx-md-0 mx-3 mt-5">
            <div className="col-md-6 text-center p-md-0 p-2 px-0 mt-md-0 mt-2 latestlandingpage-booking-book-inline">
              <img src="/images/Location-Booking.svg" alt="Location" />
              <select
                name="SelectCity"
                className="fw-700 fs-6 border-0"
                style={{background:"transparent",backgroundColor:"inherit",color:"inherit"}}
                id="SelectCity"
                value={selectedCity}
                onChange={(e) => {
                  const selectedCity = e.target.value.toLowerCase();
                  setSelectedCity(selectedCity);
                  localStorage.setItem("userLocation", selectedCity);
                  if (e.target.value != "0") {
                    data(e.target.value);
                  } else {
                    data("ahmedabad");
                  }
                }}
              >
                <option value="0" className="w-500">
                  Choose a location
                </option>
                {cityData?.map((city, i) => (
                  <option key={i} value={city.cityName.toLowerCase()}>
                    {city.cityName.charAt(0).toUpperCase() +
                      city.cityName.slice(1)}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-4 offset-md-2 col-12 text-center justify-content-sm-center px-0 mt-md-0 mt-2">
              <button
                className="fw-600 w-100 latestlandingpage-booking-btn"
                onClick={() => {
                  if (userLocation != 0 && userLocation != null) {
                    window.location.href = `/venue-search/${userLocation}/sports/${"all"}`;
                  } else if (selectedCity != 0 && selectedCity != null) {
                    window.location.href = `/venue-search/${selectedCity}/sports/${"all"}`;
                  } else {
                    setShow(true);
                  }
                }}
              >
                Search venue
              </button>
            </div>
          </div>
        </div>

        <div className="col-md-6 offset-md-1 my-4">
          <div className="latestlandingpage-booking-video-bg p-3">
            <VideoLooper
              source="/images/NewLandingPageVideo.mp4"
              type="video/mp4"
              height={windowWidth >= 768 ? "21pc" : "13pc"}
              width="100%"
              autoPlay={false}
              poster="/images/VideoPlayImage.png"
            />
          </div>
        </div>
      </div>
      <Popup show={show} onHide={() => setShow(false)}>
        <VenueSearch />
      </Popup>
    </React.Fragment>
  );
}
