import { React, useState, useEffect, useCallback, useRef } from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  CustomGetAPI,
  GetAPI,
  PostAPI,
  PutAPI,
  CustomPostAPI,
} from "../Utils/APIConnector";
import _ from "lodash";
import HelmetMetaTag from "../Utils/HelmetMetaTag/HelmetMetaTag";

export default function VenueSearch(props) {
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [cityData, setCityData] = useState();
  const [sportData, setSportData] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [showSearchList, setShowsearchList] = useState(false);
  const [sport, setSport] = useState(false);
  const SearchRef = useRef(null);
  const navigate = useNavigate();

  const goToVenueList = (id, name) => {
    //setSport(true);
    setSelectedCity(name.toLocaleLowerCase());
    window.localStorage.setItem("userLocation", name.toLocaleLowerCase());
    navigate(
      `/venue-search/${name.toLocaleLowerCase()}/${
        props.isevent === "true" ? "events" : "sports"
      }/${props.sport ? props.sport : "all"}`
    );
  };

  const handleSavedVenues = () => {
    navigate("/saved-venues");
  };

  const handleSavedQueries = () => {
    navigate("/savedqueries");
  };

  useEffect(() => {
    function handleClickOutside(ref) {
      return function (event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowsearchList(false);
        }
      };
    }
    const handleFilterClick = handleClickOutside(SearchRef);

    document.addEventListener("mousedown", handleFilterClick);

    return () => {
      document.removeEventListener("mousedown", handleFilterClick);
    };
  }, [SearchRef]);

  useEffect(() => {
    CustomGetAPI("/venue/GetAllCity").then((result) => {
      result = JSON.parse(result);
      if (result?.status === "success") {
        setCityData(result?.cities);
      }
    });
    CustomGetAPI("/venue/GetAllSport").then((result) => {
      result = JSON.parse(result);
      if (result?.status === "success") {
        setSportData(result?.sportdata);
      }
    });
  }, []);

  const delayedSearch = useCallback(
    _.debounce(async (val) => {
      if (val !== "") {
        try {
          // setLoader(true)
          await CustomGetAPI("/Venue/GetCitiesByName/" + val).then((result) => {
            if (result == undefined || result == "") {
              alert("Something went wrong");
            } else {
              result = JSON.parse(result);
              setShowsearchList(true);
              setSearchData(result?.cities);
            }
          });
        } catch (error) {
          // console.log(error);
        } finally {
          // setLoader(false)
        }
      } else {
        setShowsearchList(false);
      }
    }, 500),
    []
  );

  const handleChange = (e) => {
    const { value } = e.target;
    delayedSearch(value);
    setSearchText(value);
  };
  const isLoggedIn = window.localStorage.getItem("UserMobileNo");
  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        fetchCityName(latitude, longitude);
      });
    }
  };

  const fetchCityName = async (latitude, longitude) => {
    const apiKey = "AIzaSyBeR6pBza9LE_fFj5akK8dbfWxiST2fk38";
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
    );
    const data = await response.json();

    if (data.status === "OK") {
      const city = data.results[0].address_components.find((component) =>
        component.types.includes("locality")
      )?.long_name;
      setSearchText(city || "Location not found");
      navigate(
        `/venue-search/${city.toLocaleLowerCase()}/${
          props.isevent === "true" ? "events" : "sports"
        }/${props.sport ? props.sport : "all"}`
      );
    }
  };

  return (
    <>
      <HelmetMetaTag
        titleName="CricBuddy |Search and Book Venues Near You Online"
        canonical={window.location.href}
        ogtitle="CricBuddy | Book Sports Venues in Ahmedabad Online "
        ogtype="website"
        ogurl={window.location.href}
      />
      <div className="row px-2">
        {/* <div className="col-1">
          <img
            src="/images/backArrow.svg"
            onClick={() => {
              if (!sport) {
                navigate("/home");
              } else {
                setSport(false);
                navigate("/home");
              }
            }}
          />
        </div> */}
        {/* <div className="col-12">
          <h2 className="text-center">Select {!sport ? "City" : "Sport"}</h2>
        </div> */}
      </div>

      {/* <div className="Container my-3">
        <div className="row">
          <div className="col-6 position-relative">
            <img
              className="w-100"
              src="/images/ahmedabad.png"
              alt="Ahmedabad"
            />
            <div className="position-absolute bottom-0 w-100 text-center cityName">
              Ahmedabad
            </div>
          </div>
        </div>
        <Card
          className="venueSearchCard"
          style={{
            borderRadius: "16px",
            background: "#FBFBFB",
            border: "none",
            boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Card.Body className="my-5">
            <div className="row">
              <div className="col-12 centerAlign">
                <img src="/images/venuetop.svg" className="img-fluid" alt="" />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-12">
                <div className="mx-3 posRel">
                  <input
                    type="search"
                    className="form-control venueSearch"
                    placeholder="Search cities, places..."
                    value={searchText}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    style={{
                      padding: "6px 10px 6px 40px",
                      borderRadius: "35px",
                    }}
                  />
                  <img src="/images/searchicon.svg" className="search-icon" />
                  <img
                    src="/images/Target.svg"
                    alt=""
                    className="target-icon"
                  />
                  {showSearchList && searchData.length > 0 && (
                    <>
                      <div className="results-list" ref={SearchRef}>
                        {searchData.map((result, id) => {
                          return (
                            <div
                              className="search-result"
                              onClick={(e) =>
                                goToVenueList(result?.id, result?.name)
                              }
                              key={result?.id}
                            >
                              {result?.name}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <h5 className="mb-4 fw-bold">Popular Cities</h5>

              <div className="grid-container text-center"></div>
            </div>
            {cityData?.map((city) => (
              <button
                className="citiesbox border-0 venueButtons"
                onClick={(e) => goToVenueList("1", "Ahmedabad")}
              >
                {city?.cityName}
              </button>
            ))}
          </Card.Body>
        </Card>
        {isLoggedIn ? (
          <Card
            className="my-3"
            style={{
              borderRadius: "16px",
              background: "#FBFBFB",
              border: "none",
              boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Card.Body>
              <div className="row">
                <div className="col-4 m-auto centerAlign">
                  <img className="" src="/images/helmet.svg" alt="" />
                </div>
              </div>

              <div className="row mt-3 px-4">
                <div className="col-6 col-lg-3 m-auto centerAlign">
                  <img
                    src="/images/favouritevenues.svg"
                    alt=""
                    onClick={handleSavedVenues}
                    className="cursorClass"
                  />
                </div>
                <div className="col-6 col-lg-3 m-auto centerAlign">
                  <img
                    src="/images/savedqueries.svg"
                    alt=""
                    onClick={handleSavedQueries}
                    className="cursorClass"
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        ) : (
          <></>
        )}
      </div> */}
      {!sport ? (
        <>
          <div className="row mt-2">
            <div className="col-12">
              <div className="mx-3 posRel">
                <input
                  type="text"
                  className="form-control venueSearch"
                  placeholder="Search city"
                  value={searchText}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  style={{
                    padding: "6px 10px 6px 40px",
                    borderRadius: "5px",
                  }}
                />
                <img src="/images/searchicon.svg" className="search-icon" />
                <div
                  className="target-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleLocationClick(e);
                  }}
                >
                  <b className="location-text">Current location</b> &nbsp;
                  <img src="/images/Target.svg" alt="" className="" />
                </div>
                {showSearchList && searchData.length > 0 && (
                  <>
                    <div className="results-list" ref={SearchRef}>
                      {searchData.map((result, id) => {
                        return (
                          <div
                            className="search-result"
                            onClick={(e) =>
                              goToVenueList(result?.id, result?.name)
                            }
                            key={result?.id}
                          >
                            {result?.name}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            {cityData?.map((city) => (
              <div
                className="mb-4 mt-3 mt-md-0 col-md-1 col-3 cursor-pointer"
                onClick={(e) => {
                  //setSport(true);
                  setSelectedCity(city?.cityName.toLocaleLowerCase());
                  localStorage.setItem(
                    "userLocation",
                    city?.cityName.toLocaleLowerCase()
                  );
                  window.location.href = `/venue-search/${city?.cityName.toLocaleLowerCase()}/${
                    props.isevent === "true" ? "events" : "sports"
                  }/${props.sport ? props.sport : "all"}`;
                }}
              >
                <img
                  className="img-fluid"
                  src={`/images/${city?.cityName.toLocaleLowerCase()}.png`}
                  alt={city?.cityName.toLocaleLowerCase()}
                />
                <div className=" text-center py-1 py-md-2 cityText">
                  <b>{city?.cityName}</b>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="row">
          {sportData?.map((sport) => (
            <div
              className="col-4 col-md-2 mt-4"
              onClick={(e) => {
                navigate(
                  `/venue-search/${selectedCity}/${
                    props.isevent === "true" ? "events" : "sports"
                  }/${sport?.sportname.toLocaleLowerCase()}`
                );
              }}
            >
              <img
                className="w-100"
                src={`/images/${sport?.sportname.toLocaleLowerCase()}.png`}
                alt={sport?.sportname.toLocaleLowerCase()}
              />
              <div className=" w-100 text-center py-1 py-md-2 ">
                {sport?.sportname}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
