import React, { Fragment, useState } from "react";
import Booking from "./Booking";
import Popularplace from "./Popularplace";
import Features from "./Features";
import FAQ from "./FAQ";
import CricbuddyAppComp from "../NewLandingPage/CricbuddyAppComp";
import HelmetMetaTag from "../Utils/HelmetMetaTag/HelmetMetaTag";

export default function Index() {
  const [state, setState] = useState();
  const data = (data) => {
    setState(data);
  };
  return (
    <Fragment>
      <HelmetMetaTag
        titleName="CricBuddy | Discover and Book Sports Venues near you Online"
        desc="Find the best sports complexes and venues near your area online & book instantly with CricBuddy. Search for matches happening nearby and join in."
        canonical={window.location.href}
        ogtitle="CricBuddy | Discover and Book Sports Venues near you Online"
        ogdesc="Find the best sports complexes and venues near your area online & book instantly with CricBuddy. Search for matches happening nearby and join in."
        ogtype="website"
        ogurl={window.location.href}
        ogimg="https://cricbuddy.in/logo192.png"
      />
      {/* <div className="px-0 row" style={{ overflowX: "hidden" }}> */}
      <div style={{ backgroundColor: "#FFFCFC", overflowX: "hidden" }}>
        <Booking data={data} />
        <Popularplace data={state} />
        <Features />
        <CricbuddyAppComp />
        <FAQ />
      </div>
      {/* </div> */}
    </Fragment>
  );
}
