import React, { useEffect, useState } from "react";
import SportSelection from "./SportSelection";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import HelmetMetaTag from "../Utils/HelmetMetaTag/HelmetMetaTag";
import GetApp from "../GetApp/GetApp";
import Cookies from "universal-cookie";
import Popup from "../LiveScoreCardpopups/Popup";
import VenueSearch from "../VenueListing/venueSearch";
import { event } from "jquery";

export default function NewHomePage() {
  const navigate = useNavigate();
  const userType = window.localStorage.getItem("UserType");
  const [loading, setLoading] = useState(true);
  const userLocation = window.localStorage.getItem("userLocation");
  const [show, setShow] = useState({
    popup: false,
    sport: "all",
    event: true,
  });
  const [width, setWidth] = useState(window.innerWidth);
  const location = useLocation();
  const cookies = new Cookies();
  if (location?.search?.split("=")[1] === "android") {
    cookies.set("Isapp", "true", {
      maxAge: 1440000,
    });
  }
  const [Isapp, setIsapp] = useState(false);
  useEffect(() => {
    if (cookies.get("Isapp") == undefined) {
      setIsapp(false);
    } else {
      setIsapp(true);
    }
  }, [cookies.get("Isapp")]);
  useEffect(() => {
    localStorage.removeItem("filterObjects");
    if (userType == null || userType === undefined || userType === "") {
      window.location.href = "/home";
    }
    Data();
  }, []);
  const Data = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <React.Fragment>
      <div style={{ overflow: "hidden" }}>
        {" "}
        <HelmetMetaTag
          titleName="Book Sports Venues| Play Cricket | Find Players | List Venue"
          desc="Discover, book sports venues, play cricket, find players, and list your venue with CricBuddy.Your one-stop platform for all things sports!"
          canonical={window.location.href}
          ogtitle="Book Sports Venues| Play Cricket | Find Players | List Venue"
          ogdesc="Discover, book sports venues, play cricket, find players, and list your venue with CricBuddy.Your one-stop platform for all things sports!"
          ogtype="website"
          ogurl={window.location.href}
          ogimg="https://cricbuddy.in/logo192.png"
        />
        <div class="row">
          <div className="col-12 position-relative">
            <img
              className="w-100 img-fluid Homepagebanner"
              // style={{ maxHeight: "200px" }}
              src={`/images/${
                width >= 900 ? "Homepagebanner" : "Homepagebanner_mobile"
              }.png`}
              alt=""
            />
            <div className="position-absolute d-flex align-items-center l-50  w-100 h-100 bottom-0 ">
              <div className="">
                <p className="getBannerFont m-0 pb-1">
                  Get{" "}
                  <span className="discount-badge">
                    <span>10% Off</span>
                  </span>{" "}
                  on your <br /> venue booking
                </p>

                {!Isapp && (
                  <>
                    <GetApp />
                  </>
                )}
              </div>
            </div>
            {!Isapp && (
              <div className="position-absolute  appex">*App exclusive</div>
            )}
          </div>
        </div>
        <div className="row px-md-5 px-2">
          <div className="col-12 mt-3 ">
            <b>Book your favorite sports venue online</b>
          </div>
        </div>
        <div className="row px-md-5 px-2 py-3">
          <SportSelection width={width} />
        </div>
        <div className="row mt-3 position-relative">
          <img
            src="/images/AuditoriumBaneer.png"
            className="img-fluid AuditoriumBaneerBgImg"
            alt=""
          />
          <div className="position-absolute auditoriumBannerText">
            <p>
              Looking for a small Auditorium to organize or <br /> host an
              event?
            </p>
            <button
              className="mt-3 auditoriumBannerBtn px-3 py-1 fw-600"
              onClick={() => {
                if (userLocation) {
                  navigate(
                    `/venue-search/${userLocation.toLocaleLowerCase()}/events/auditorium`
                  );
                } else {
                  setShow({ popup: true, sport: "auditorium", event: true });
                }
              }}
              // onClick={() => {
              //   Swal.fire({
              //     title: "Coming Soon",
              //     text: "This feature allows you to find and book auditorium near you.",
              //     showConfirmButton: true,
              //     confirmButtonText: "Ok",
              //   }).then((result) => {});
              // }}
            >
              Book Auditorium
            </button>
          </div>
        </div>
        <div className="row mt-3 position-relative">
          <img
            src="/images/ListVenueBanner.png"
            className="img-fluid w-100 p"
            alt=""
          />
          <div className="position-absolute auditoriumBookingText">
            <button
              className=" py-2 fw-600"
              onClick={(e) => {
                navigate(
                  userType === "Hybrid" ||
                    userType === "Venueowner" ||
                    userType === "Staff"
                    ? "/home-venue-owner"
                    : userType === "Admin"
                    ? "/home-venue-owner"
                    : "/venue-information"
                );
              }}
            >
              {userType === "Hybrid" ||
              userType === "Venueowner" ||
              userType === "Staff"
                ? "Go to My Venues"
                : userType === "Admin"
                ? "Go to Venue Request "
                : "List your Venue with us"}{" "}
              <i className="fa fa-arrow-right mx-1" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div className="row px-md-5 px-2 mt-md-5 mt-3">
          <div className="col-6 text-center ">
            <div
              className="FindBorder p-0 cursor-pointer"
              onClick={() => {
                Swal.fire({
                  title: "Under Progress",
                  text: "This feature allows you to find matches happening around you or find players looking for match to play. This feature will be live soon, please check again in few days.",
                  showConfirmButton: true,
                  confirmButtonText: "Ok",
                }).then((result) => {});
              }}
            >
              <img
                className="img-fluid w-100"
                src="/images/FindPlayer.png"
                alt="Find Player"
              />
              <p className="py-3 fw-600">Find a Player for your match</p>
            </div>
          </div>
          <div className="col-6 text-center ">
            <div
              className="FindBorder p-0 cursor-pointer"
              onClick={() => {
                Swal.fire({
                  title: "Under Progress",
                  text: "This feature allows you to find matches happening around you or find players looking for match to play. This feature will be live soon, please check again in few days.",
                  showConfirmButton: true,
                  confirmButtonText: "Ok",
                }).then((result) => {});
              }}
            >
              <img
                className="img-fluid w-100"
                src="/images/FindMatch.png"
                alt="Find Player"
              />
              <p className="py-3 fw-600">Find a Match & join to play</p>
            </div>
          </div>
        </div>
      </div>
      <Popup
        show={show?.popup}
        onHide={() => setShow({ popup: false, sport: "all" })}
      >
        <VenueSearch isevent={"true"} sport={show?.sport} />
      </Popup>
    </React.Fragment>
  );
}
